import React from "react";
import ImageWrapper from "../Image/ImageWrapper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  addToWishlist,
  removeFromWishlist,
  togglePlaceOrderModal
} from "../../store/actions";

const BLOCK_CLASS = "productlisting";

const Card = (props) => {
  const wishlist = useSelector((state) => state.wishlist);
  const isUserLoggedIn = useSelector((state) => state.isUserLoggedIn);
  const dispatch = useDispatch();

  const isInWishlist = () => {
    let currentDisplayedProduct = wishlist?.items?.filter((item) => {
      if (item.variant_sku == props.data.variant_sku) {
        return item;
      }
    });

    return currentDisplayedProduct?.length > 0 ? true : false;
  }

  const onWishlistClick = () => {
    if (!isUserLoggedIn) {
      dispatch(togglePlaceOrderModal({ type: "login" }));
      return
    }    

    if (isInWishlist()) {
      dispatch(removeFromWishlist(props.data.variant_sku));
    } else {
      dispatch(addToWishlist(props.data.variant_sku));
    }
  };

  const getProductUrl = (data) => {
    let artName = props.data?.variant_properties?.art?.replaceAll(" ", "-");
    let productName = props.data?.name.replaceAll(" ", "-")?.replaceAll("/", "-");
    let sku = props.data.variant_sku;
    
    return `/product/${artName}-${productName}-${sku}`;
  }

  return (
    <div className={`${BLOCK_CLASS}__card`} key={props.data.product_sku}>
      <Link
        to={getProductUrl()}
      >
        <ImageWrapper
          externalUrl={props.data.primary_image}
          name="women-kurti"
          alt=""
          classes={`${BLOCK_CLASS}__image`}
          key={props.data.primary_image}
        />
      </Link>
      {props?.data?.tags?.map((tag) => {
        return (
          <Link
            to={getProductUrl()}
            key={"listing_" + props.data.variant_sku}
          >
            <p className={`${BLOCK_CLASS}__badge`}>{tag.name}</p>
          </Link>
        );
      })}

      <div className={`${BLOCK_CLASS}__info-wrapper`}>
        <Link
          to={getProductUrl()}
          key={"listing_" + props.data.variant_sku}
        >
          <p className={`${BLOCK_CLASS}__name`}>
            {props.data.name || props.data.product_name}
          </p>
        </Link>
        <div className={`${BLOCK_CLASS}__wishlist-wrapper`}>
          <ImageWrapper
            name={isInWishlist() ? "in-wishlist" : "wishlist"}
            alt="Wishlist button"
            classes={`${BLOCK_CLASS}__wishlist`}
            onclick={onWishlistClick}
          />
        </div>
      </div>

      {props?.data?.price ? (
        <Link
          to={getProductUrl()}
          key={"listing_" + props.data.variant_sku}
        >
          <p className={`${BLOCK_CLASS}__price`}>
            <span>Rs.</span> {props.data.price}
          </p>
        </Link>
      ) : null}

      {props.data.quantity < 5 ? (
        <p className={`${BLOCK_CLASS}__label`}>Only few Left</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Card;
